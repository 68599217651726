const countryCurrencyMap = {
  AD: 'EUR',
  AS: 'USD',
  AT: 'EUR',
  AU: 'AUD',
  AX: 'EUR',
  BE: 'EUR',
  BL: 'EUR',
  BQ: 'USD',
  BR: 'BRL',
  CA: 'CAD',
  CC: 'AUD',
  CH: 'CHF',
  CK: 'NZD',
  CL: 'CLP',
  CN: 'CNY',
  CO: 'COP',
  CX: 'AUD',
  CY: 'EUR',
  CZ: 'CZK',
  DE: 'EUR',
  DK: 'DKK',
  EC: 'USD',
  EE: 'EUR',
  ES: 'EUR',
  FI: 'EUR',
  FM: 'USD',
  FO: 'DKK',
  FR: 'EUR',
  GB: 'GBP',
  GF: 'EUR',
  GG: 'GBP',
  GL: 'DKK',
  GP: 'EUR',
  GR: 'EUR',
  GS: 'GBP',
  GU: 'USD',
  HK: 'HKD',
  HM: 'AUD',
  IE: 'EUR',
  IM: 'GBP',
  IN: 'INR',
  IO: 'USD',
  IT: 'EUR',
  JE: 'GBP',
  JP: 'JPY',
  KI: 'AUD',
  KR: 'KRW',
  LI: 'CHF',
  LT: 'EUR',
  LU: 'EUR',
  LV: 'EUR',
  MC: 'EUR',
  ME: 'EUR',
  MF: 'EUR',
  MH: 'USD',
  MP: 'USD',
  MQ: 'EUR',
  MT: 'EUR',
  MX: 'MXN',
  NF: 'AUD',
  NL: 'EUR',
  NR: 'AUD',
  NU: 'NZD',
  NZ: 'NZD',
  PL: 'PLN',
  PM: 'EUR',
  PN: 'NZD',
  PR: 'USD',
  PT: 'EUR',
  PW: 'USD',
  RE: 'EUR',
  SE: 'SEK',
  SG: 'SGD',
  SI: 'EUR',
  SK: 'EUR',
  SM: 'EUR',
  SV: 'USD',
  TC: 'USD',
  TF: 'EUR',
  TK: 'NZD',
  TL: 'USD',
  TR: 'USD',
  TV: 'AUD',
  UM: 'USD',
  US: 'USD',
  VA: 'EUR',
  VG: 'USD',
  VI: 'USD',
  XK: 'EUR',
  YT: 'EUR',
};

export default function (countryCode) {
  return countryCurrencyMap[countryCode] || 'USD';
}
