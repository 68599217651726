<template>
  <header 
    class="bg-light d-flex flex-nowrap justify-content-center w-100 float-top"
    id="cart-header"
  >
    <div class="w-80 w-100 d-flex flex-row flex-wrap justify-content-between gap-4 m-0 align-middle px-3 py-4">
      <div id="logo-with-account" class="container d-flex flex-row flex-wrap justify-content-between gap-4 m-0 align-middle px-3 align-items-center">
        <div id="speedify-img-container" class="text-start px-3">
          <a href="https://speedify.com">
            <img id="speedify-logo" alt="Speedify" class="logo" :src="speedifyLogo" />
            <img id="speedify-icon" alt="Speedify" class="logo" :src="speedifyIcon" />
          </a>
        </div>
        <AccountCard v-if="checkoutData.loggedIn"/> 
        <div class="px-4" v-else>
          Already have a Speedify account?
          <span>
            <a class="btn btn-secondary mx-2" :href="loginUrl">
              <strong>Sign in</strong>
            </a>
          </span>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
import defaultUserSvg from '@/assets/svg/default-user.svg';
import speedifyLogo from '@/assets/images/speedify-logo-black.png';
import speedifyIcon from '@/assets/images/speedify-s-logo.png';
import AccountCard from '@/components/AccountCard.vue';
import { storeToRefs } from 'pinia';
import { useCheckoutStore } from '@/stores/checkout';

const checkoutStore = useCheckoutStore();
const { checkoutData } = storeToRefs(checkoutStore);

const subsMgmtV2Url = import.meta.env.VITE_SUBS_MGMT_V2_URL;
const loginUrl = subsMgmtV2Url + `/login?redirect=${encodeURIComponent(window.location.href)}`;
</script>
<style scoped>
#cart-header {
  max-height: 109px;
}

#speedify-icon {
  display: none;
}

@media (width < 768px) {
  #cart-header {
    max-height: 180px !important;
  }

  #speedify-logo {
    display: none;
  }

  #speedify-icon {
    display: block;
  }

  #speedify-img-container {
    padding: 0px !important;
  }

  #logo-with-account {
    flex-wrap: nowrap !important;
    margin-left: 20px !important;
    gap: 4px !important;
    justify-content: center !important;
  }
}
</style>
