<script setup>
import { RouterView } from 'vue-router';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

import { useGeoIPStore } from '@/stores/geoip';

const geoipStore = useGeoIPStore();
geoipStore.refresh();
</script>

<template>
  <RouterView :key="$route.fullPath" />
</template>
