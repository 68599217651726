<template>
  <div class="col-12 col-md-6 my-2">
    <label for="country" class="small">Country</label>
    <select id="country" class="form-control" name="country" required data-recurly="country" @change="updateCountry" v-model="checkoutData.country">
      <option v-for="v in countryList" :key="v.code" :selected="geoIPStore.details.country === v.code" :value="v.code">
        {{ v.code }} - {{ v.name }}
      </option>
    </select>
    <small style="color: red" v-if="formError">Please select a country.</small>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useGeoIPStore } from '@/stores/geoip';
import { useCheckoutStore } from '@/stores/checkout';
import countryList from '@/vars/countryList';

const geoIPStore = useGeoIPStore();
const { checkoutData } = useCheckoutStore();

defineProps({ formError: Boolean });

const updateCountry = e => {
  geoIPStore.update(e.target.value);
};
</script>
