export const numberToCountryLocale = (n, countryCode, currency) => {
  let countryLocale = countryCode || 'US';

  if (countryCode === 'IN') {
    countryLocale = 'en-IN';
  }

  const opts = {};
  if (currency) {
    opts.style = 'currency';
    opts.currency = currency;
  }

  // For some countries, we default to USD since we don't support
  // their currency
  if (countryCode !== 'US' && currency === 'USD') {
    return new Intl.NumberFormat(`en-${countryCode}`, opts).format(n);
  }

  return new Intl.NumberFormat(countryLocale, opts).format(n);
};

