<template>
  <div>
    <h4 class="mb-3 mt-4 s-h4 text-start fw-bold" v-if="!checkoutData.loggedIn">1: Create Your Account</h4>
    <h4 class="mb-3 mt-4 s-h4 text-start fw-bold" v-else>Your Account</h4>
    <div class="mb-1">
      <label for="email" v-if="!checkoutData.loggedIn">Email address you will use to log in:</label>
      <input
        maxlength="64"
        type="email"
        class="form-control mt-1"
        id="email"
        data-recurly="email"
        name="email"
        placeholder="you@example.com"
        v-model="checkoutData.email"
        :disabled="checkoutData.loggedIn === true"
      />
      <small style="color: red" v-if="invalidEmail && displayFormError">
        Must be a valid email address
        <br />
      </small>
      <label class="mt-3" for="email-repeat" v-if="!checkoutData.loggedIn">Please confirm your Email address:</label>
      <input
        maxlength="64"
        type="email"
        class="form-control mt-1"
        id="email-repeat"
        name="email-repeat"
        placeholder="you@example.com"
        v-model="checkoutData.emailRepeat"
        v-if="!checkoutData.loggedIn"
      />
      <small style="color: red" v-if="invalidEmailRepeat && displayFormError">
        Email addresses must match
      </small>
      <!-- eslint-disable-next-line max-len -->
      <p class="helper-text mt-1 small" v-if="!checkoutData.loggedIn">
        We will not share your email address with third parties, and will only contact you when necessary to ensure the best service.
      </p>
      <div v-if="!checkoutData.loggedIn">
        <h1 id="thirdPartyOauthOffer"><span><strong>OR SIGN IN WITH</strong></span></h1>
        <div class="d-flex flex-row justify-content-evenly" style="flex: 1 1 100%;">
          <button
            class="oauth-button btn btn-light d-flex flex-grow-1 justify-content-center align-items-center"
            type="button"
            @click="loginWithOauth('apple')"
          >
            <img :src="appleLogo" style="height:27px; margin-bottom: 3px"/>
            <div class="m-2 w-50"><strong>Apple</strong></div>
          </button>
          <button
            class="oauth-button btn btn-light d-flex flex-grow-1 justify-content-center align-items-center"
            type="button"
            @click="loginWithOauth('google')"
          >
            <img :src="googleLogo" style="height:40px;"/>
            <div class="m-2 w-50"><strong>Google</strong></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email, sameAs } from '@vuelidate/validators';
import { storeToRefs } from 'pinia';
import { useCheckoutStore } from '@/stores/checkout';
import googleLogo from '@/assets/svg/google-icon.svg';
import appleLogo from '@/assets/svg/apple-logo-black.svg';

const subsMgmtV2Url = import.meta.env.VITE_SUBS_MGMT_V2_URL;

const checkoutStore = useCheckoutStore();
const { checkoutData } = storeToRefs(checkoutStore);

const props = defineProps({
  displayFormError: Boolean,
  invalidEmail: Boolean,
  invalidEmailRepeat: Boolean,
});

const loginWithOauth = (provider) => {
  window.location.href = subsMgmtV2Url + `/auth/${provider}?redirect=${encodeURIComponent(window.location.href)}`;
};
</script>
<style scoped>
.oauth-button {
  max-height: 100px;
  max-width: 45%;
  flex: 1 1 100%;
}
#thirdPartyOauthOffer {
  overflow: hidden;
  text-align: center;
  font-size: 12px;
}

#thirdPartyOauthOffer::before,
#thirdPartyOauthOffer::after {
  background-color: #000;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
  opacity: 0.25;
}

#thirdPartyOauthOffer::before {
  right: 0.5em;
  margin-left: -50%;
}

#thirdPartyOauthOffer::after {
  left: 0.5em;
  margin-right: -50%;
}
</style>
