import { createApp } from 'vue';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';

import router from './router/index';
import App from './App.vue';
import '@/assets/css/style.css';

const pinia = createPinia();
const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENV,
  release: import.meta.env.SENTRY_RELEASE,
});

app.use(pinia);
app.use(router);
app.mount('#app');
