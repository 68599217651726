import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

export const useAnalyticsStore = defineStore('analytics', () => {
  const route = useRoute();
  const utmSource = ref(route.query?.utm_source);
  const utmMedium = ref(route.query?.utm_medium);
  const utmCampaign = ref(route.query?.utm_campaign);
  const utmContent = ref(route.query?.utm_content);
  const inapp = ref(Number(route.query?.inapp));

  const details = computed(() => ({
    utmSource: utmSource.value,
    utmMedium: utmMedium.value,
    utmCampaign: utmCampaign.value,
    utmContent: utmContent.value,
    inapp: inapp.value,
  }));

  return { details };
});
