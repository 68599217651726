export const orderCompleteRedirect = (loggedIn) => {
  if (loggedIn) {
    // kind of awkward to see the /welcome page before the sub is reflected on smv2, so give it ample time
    setTimeout(() => {
      window.location.href = import.meta.env.VITE_SUBS_MGMT_V2_URL + '/account?utm_redirect=cart';
    }, 5000);
  }
  let orderCompleteURL = `${import.meta.env.VITE_RECURLY_REDIRECT_URL}?`;
  const query = new URLSearchParams(window.location.search);
  for (const pair of query.entries()) {
    orderCompleteURL += `${pair[0]}=${pair[1]}&`;
  };
  window.location.href = orderCompleteURL.slice(0, -1);
};
