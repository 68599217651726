<template>
  <div 
    class="exit-intent-popup"
    :class="{
      visible: state.window.ad.shouldShow,
    }"
    @click="hideAd"
  >
    <div @click.stop="" class="special-offer text-center py-4" style="width:60%; max-width:600px">
      <span class="close" @click="hideAd">x</span>
      <h6 class="fw-bold pink caps my-2">DON'T WANT <i class="fst-italic">ANOTHER</i> SUBSCRIPTION?</h6>
      <h3 class="fw-bolder fs-4 w-100">Save 70% on a 2-Year Pass (Non-Renewing)</h3>
      <div class="">
        <!-- todo: remove hardcoded values? don't care right now supposedly -->
        <!-- https://connectify.slack.com/archives/CNKVCR1NV/p1703096306731089?thread_ts=1703093159.310449&cid=CNKVCR1NV -->
        <h1 class="fw-bolder fs-1 mb-0"><sup>$</sup>99<sup>.99</sup></h1> 
        <h5 class="fw-bolder">billed once</h5>
      </div>
      <div class="w-100 text-center my-2">2 Years of Speedify for only <s class="red">$333.30</s> $99.99</div>
      <div class="w-100 d-grid justify-content-stretch py-2">
        <p @click="redeemAdDeal" class="btn btn-green btn-block text-white fw-bolder fs-4">Get This Deal</p>
      </div>
      <p class="mt-2">Hurry, this offer ends soon! <span id="timer">{{ state.window.ad.timer }}</span></p>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useMouse } from '@vueuse/core'
import { addDays, addMinutes, differenceInSeconds } from 'date-fns';

const { y: mouseYAxis } = useMouse({ touch: false });
const route = useRoute();
const router = useRouter();

onMounted(async () => {
  state.window.pageLoad = new Date();
});

// How long the promo countdown timer lasts
const promoCountdownMinutes = 4;
// How much buffer in seconds the user is given before we may show them the promo
// We don't want to show it to them right away if they move their mouse to the top
const promoOnLoadBufferSeconds = 5;
// Localstorage key for next date that we can show a user an ad/promo
const promoNextDateKey = 'speedify-ad-next';

const state = reactive({
  window: {
    // used in conjunction with promoOnLoadBufferSeconds so that we know how much
    // time has elapsed before we show users ads and promos
    pageLoad: null,
    ad: {
      shouldShow: false, 
      shownAt: null,
      timer: '',
      currentPromo: {
        plan: 'speedify-unlimited-twoyear-nonrenew',
        inapp: 0,
        couponCode: 'dontgo',
      },
    },
  },
});

const nextAdVisibilityDate = () => new Date(localStorage.getItem(promoNextDateKey) || 0);
const nextAdVisibilityDatePassed = () => new Date() > nextAdVisibilityDate();

const zeroPad = (num, places) => String(num).padStart(places, '0');

const hideAd = () => {
  state.window.ad.shouldShow = false;
  state.window.ad.shownAt = null;
};

/*
  This sets the countdown timer for the popup ad promo
*/
setInterval(() => {
  // If the ad isn't being shown, don't countdown
  if (!state.window.ad.shownAt) {
    return;
  }

  const goal = addMinutes(state.window.ad.shownAt, promoCountdownMinutes);
  const diff = differenceInSeconds(goal, new Date());

  // We want to create a false sense of ugency. so if the timer has counted
  // all the way down, we will still show the ad but not the timer.
  // We will still offer the deal
  if (diff <= 0) {
    state.window.ad.timer = '';
    return;
  }

  const minutes = diff / 60;
  const seconds = diff % 60;

  state.window.ad.timer = `${Math.floor(minutes)}:${zeroPad(seconds, 2)}`;
}, 1000);

const redeemAdDeal = () => {
  router.push({
    name: 'CheckoutCart',
    query: {
      plan: state.window.ad.currentPromo.plan,
      inapp: state.window.ad.currentPromo.inapp,
      coupon: state.window.ad.currentPromo.couponCode,
    },
  });
};

/*
  If the user moves their mouse towards the top bar (tab area), we will determine
  whether to show them an ad/promo
  More discussion: https://github.com/Connectify/speedify-cart/pull/165
*/
const shouldShowUserAd = computed(() => {
  // can't read mouse? don't show user ad
  // intentionally not using strict equality. catch undef and null but not 0
  if (mouseYAxis.value == null) {
    return false;
  }

  if (!nextAdVisibilityDatePassed()) {
    return false;
  }

  if (route.query.plan === state.window.ad.currentPromo.plan) {
    return false;
  }

  let timeDiffSec;

  if (state.window.pageLoad) {
    timeDiffSec = ((new Date().getTime() - state.window.pageLoad.getTime()) / 1000);
    if (timeDiffSec > promoOnLoadBufferSeconds && mouseYAxis.value < 10) {
      return true;
    }
  } else {
    // if we don't know when the page was loaded, let's not force an ad on them
    return false;
  }

  return false;
});

/*
  If we decide that the criteria fit to show a user an ad, then let's check to make
  sure we haven't shown them one in the last 24 hours and record the details of when
  we last showed them the ad
*/
watch(shouldShowUserAd, (v) => {
  if (v) {
    if (nextAdVisibilityDatePassed()) {
      localStorage.setItem(promoNextDateKey, addDays(new Date(), 1));
      state.window.ad.shouldShow = true;
      state.window.ad.shownAt = new Date();
    }
  }
});
</script>
