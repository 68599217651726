<template>
  <div class="dropdown" id="dropdown-container">
    <button 
      class="btn dropdown-toggle text-start d-flex align-items-center gap-3"
      type="button"
      id="account-dropdown"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <img :src="defaultUserSvg" style="height: 25px;" />
      <div>
        <strong>My account</strong>
        <br/>
        <span class="text-muted">{{ checkoutData.email }}</span> 
      </div>
    </button>
    <ul class="dropdown-menu" id="account-menu" aria-labelledby="account-dropdown">
      <li><a class="dropdown-item" :href="subsMgmtV2Url + '/account'" target="_blank" rel="noopener noreferrer">Account settings</a></li>
      <li><a class="dropdown-item" href="https://speedify.com/help/" target="_blank" rel="noopener noreferrer">Support</a></li>
      <li><a class="dropdown-item" @click="onLogoutClick">Sign Out</a></li>
    </ul>
  </div>
</template>
<script setup>
import defaultUserSvg from '@/assets/svg/default-user.svg';
import { storeToRefs } from 'pinia';
import { useCheckoutStore } from '@/stores/checkout';
import { logoutFromAccount } from '@/services/backend';
import { onMounted } from 'vue';

const checkoutStore = useCheckoutStore();
const { checkoutData } = storeToRefs(checkoutStore);

const subsMgmtV2Url = import.meta.env.VITE_SUBS_MGMT_V2_URL;

const logoutUrl = subsMgmtV2Url + '/logout';

const onLogoutClick = async () => {
  await logoutFromAccount();
  window.location.href = subsMgmtV2Url + `/login?redirect=${window.location.href}`;
};

onMounted(() => {
  // funky styling stuff for bootstrap dropdown. would like to get rid of black border on click
  const dropdown = document.getElementById('account-dropdown');
  dropdown.addEventListener('show.bs.dropdown', (e) => {
    e.target.classList.add('account-dropdown-open');
  });
  dropdown.addEventListener('hide.bs.dropdown', (e) => {
    e.target.classList.remove('account-dropdown-open');
  });
});

</script>
<style scoped>

#dropdown-container {
  margin-right: 1.5rem;
}
.account-dropdown-open {
  box-shadow: 1px;
  border-bottom: none !important;
  border-color: var(--bs-border-color-translucent) !important;
  border-radius: .25rem .25rem 0 0;
}
#account-dropdown:hover {
    transition: none !important;
}
#account-menu {
  min-width: 100%;
  background-color: rgb(241, 241, 241) !important;
  box-shadow: 1px;
  border-top: 0px;
  border-radius: 0 0 .25rem .25rem;
}

.dropdown-menu {
  transform: translate3d(0px, 60px, 0px) !important;
}

.dropdown-menu {
  border-radius: 0 .25rem .25rem .25rem;
}

.btn {
  box-shadow: 1px !important;
}
</style>
