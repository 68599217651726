import * as jose from 'jose';

const subsMgmtV2Url = import.meta.env.VITE_SUBS_MGMT_V2_URL;
const tokenName = import.meta.env.VITE_SESSION_TOKEN_NAME;

const jwks = jose.createRemoteJWKSet(new URL(`${subsMgmtV2Url}/.well-known/jwks.json`));
export const verifyToken = async () => {
  const cookies = document.cookie.split('; ').map(v => v.split(/=(.*)/s).map(decodeURIComponent));
  const cookiesMap = Object.fromEntries(cookies);
  const token = cookiesMap[tokenName];
  try {
    const verified = await jose.jwtVerify(token, jwks);
    const { sub: userid, email, iat, role } = verified.payload;
    return { userid, email, iat, role };
  } catch (err) {
    return null;
  }
};

