<template>
  <h4 class="accordion-header" id="headingFour">
    <button
      type="button"
      class="accordion-button collapsed d-flex flex-row justify-content-between gap-2 stm-btn-accordion-apple-pay"
      data-bs-toggle="collapse"
      data-bs-target="#accordion-apple-pay"
      aria-expanded="false"
      aria-controls="accordion-apple-pay"
    >
      <div class="d-flex justify-content-between w-100">
        Apple Pay
        <img alt="Apple Pay supported" class="icon" :src="appleStoreLogoSvg" />
      </div>
    </button>
  </h4>
  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
  <div
    id="accordion-apple-pay"
    class="accordion-collapse collapse"
    aria-labelledby="headingOne"
    data-bs-parent="#paymentAccordion"
  >
    <div class="accordion-body card-element row">
      <div class="alert alert-danger mt-3 mb-0 stm-msg-applePay-error py-3" role="alert" v-if="state.purchaseFailed || state.applePayError">
        <i class="fas fa-exclamation-circle"></i>
        &nbsp;Error
        <hr />
        <p v-if="state.purchaseFailed">
          Unable to process purchase:
          <span v-html="state.failureError"></span>
        </p>
        <div v-if="state.applePayError">
          <p>
            Apple Pay Error: {{ state.applePayError }}
            <br />
            If this persists, please contact
            <a href="support@speedify.com">support@speedify.com</a>
          </p>
        </div>
      </div>
      <div class="accordion-body card-element row justify-content-center">
        <div 
          class="apple-pay-button-with-text apple-pay-button-black-with-text" 
          @click="handleApplePayClick"
        >
        </div>
        <div class="row d-flex flex-nowrap justify-content-around w-100 text-center mt-3">
          <div class="small col">
            <img alt="secure" class="icon" :src="lockSvg" />
            Encrypted and secure payments
          </div>
          <div class="small col align-top">
            <img alt="money back" class="icon" :src="clockRotateLeftSvg" />
            30-Day Money Back Guarantee
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, onMounted } from 'vue';
import * as Sentry from '@sentry/vue';
import { storeToRefs } from 'pinia';
import { createPurchase } from '@/services/backend';
import { useAnalyticsStore } from '@/stores/analytics';
import { useCheckoutStore } from '@/stores/checkout';
import getLocale from '@/services/getLocale';
import { orderCompleteRedirect } from '@/services/orderCompleteURL';

import appleStoreLogoSvg from '@/assets/svg/apple-store-logo.svg';
import lockSvg from '@/assets/svg/lock.svg';
import clockRotateLeftSvg from '@/assets/svg/clock-rotate-left.svg';

const analyticsStore = useAnalyticsStore();
const checkoutStore = useCheckoutStore();
const { checkoutData } = storeToRefs(checkoutStore);

const locale = getLocale();

const props = defineProps({
  disableButton: Boolean,
  recurlyPricing: {
    type: Object,
    required: true,
  },
  plan: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  'hideApplePay',
  'showApplePayVuelidate',
  'processingPayment',
  'paymentFailed',
  ]);

const state = reactive({
  purchaseFailed: false,
  failureError: '',
  applePayError: '',
});

let applePay;

const handleApplePayClick = () => {
  if (props.disableButton) {
    emit('showApplePayVuelidate');
    return;
  }
  state.purchaseFailed = false;
  state.failureError = '';
  applePay.begin();
};

onMounted(() => {
  applePay = recurly.ApplePay({
    braintree: {
      clientAuthorization: import.meta.env.VITE_BRAINTREE_KEY,
      webAuthentication: true,
    },
    country: 'US',
    label: props.plan,
    pricing: props.recurlyPricing,
    currency: 'USD',
  });

  applePay.on('error', err => {
    if (err.name === 'apple-pay-not-available') {
      state.applePayError = 'Apple Pay is not configured on this device.'
      return;
    } else if (err.name !== 'apple-pay-payment-failure' && err.name?.startsWith('apple-pay')) {
      emit('hideApplePay');
      return;
    }
    Sentry.captureException(err);
    state.applePayError = 'Apple Pay was not able to process the transaction. Please use a different payment method or try again later.';
  });

  applePay.on('token', async token => {
    emit('processingPayment');
    const result = await createPurchase(checkoutData.value, undefined, token.id, analyticsStore.details, 'apple_pay');
    if (result.status === 'success') {
      orderCompleteRedirect(checkoutData.value.loggedIn);
    } else if (result.error?.errorCode === 'ERROR_MISSING_SPEEDIFY_LICENSE'){
      checkoutData.value.isPaid = false;
    } else {
      state.purchaseFailed = true;
      state.failureError = result.error.message;
      emit('paymentFailed');
    }
  });
});
</script>
<style scoped>
@supports (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button-with-text {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
        -apple-pay-button-type: check-out;
    }
    .apple-pay-button-with-text > * {
        display: none;
    }
    .apple-pay-button-black-with-text {
        -apple-pay-button-style: black;
    }
    .apple-pay-button-white-with-text {
        -apple-pay-button-style: white;
    }
    .apple-pay-button-white-with-line-with-text {
        -apple-pay-button-style: white-outline;
    }
}


@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button-with-text {
        --apple-pay-scale: 1; /* (height / 32) */
        display: inline-flex;
        justify-content: center;
        font-size: 12px;
        border-radius: 5px;
        padding: 0px;
        box-sizing: border-box;
        min-width: 200px;
        min-height: 32px;
        max-height: 64px;
    }
    .apple-pay-button-black-with-text {
        background-color: black;
        color: white;
    }
    .apple-pay-button-white-with-text {
        background-color: white;
        color: black;
    }
    .apple-pay-button-white-with-line-with-text {
        background-color: white;
        color: black;
        border: .5px solid black;
    }
    .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: black;
    }
    .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text > .logo {
        background-image: -webkit-named-image(apple-pay-logo-black);
        background-color: white;
    }
    .apple-pay-button-with-text > .text {
        font-family: -apple-system;
        font-size: calc(1em * var(--apple-pay-scale));
        font-weight: 300;
        align-self: center;
        margin-right: calc(2px * var(--apple-pay-scale));
    }
    .apple-pay-button-with-text > .logo {
        width: calc(35px * var(--scale));
        height: 100%;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 0 50%;
        margin-left: calc(2px * var(--apple-pay-scale));
        border: none;
    }
}
</style>
