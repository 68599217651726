import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

import countryCurrencyMap from '@/services/countryCurrencyMap';

export const useGeoIPStore = defineStore('geoip', () => {
  const directoryUrl = import.meta.env.VITE_DIRECTORY_URL;
  const country = ref('US');
  const currency = ref('USD');
  const zipCode = ref('');

  const refresh = async () => {
    try {
      const response = await fetch(`${directoryUrl}/api/v1/location`);
      const result = await response.json();
      country.value = result.results?.maxmind?.countryCode || 'US';
      zipCode.value = result.results?.ip2Location?.zipCode || '';
    } catch (err) {
      country.value = 'US';
    }
    currency.value = countryCurrencyMap(country.value);
  };

  const update = (countryCode) => {
    country.value = countryCode;
    currency.value = countryCurrencyMap(country.value);
  };

  const details = computed(() => ({
    country: country.value,
    currency: currency.value,
    zipCode: zipCode.value,
  }));

  return { details, refresh, update };
});
